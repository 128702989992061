<template>
  <v-app class="app">
    <div>
      <bml-app-bar
        ref="appbar"
        :show-menu-nav="false"
        :drawer.sync="showDrawer"
        :username="(me && me.username) || ''"
        :menu-nav-props="{
          img: avatar,
          showThemeSwitcher: false,
          showLogout: isUserLoggedIn
        }"
        :dense="false"
        color="primary"
        class="filled"
        :title="title.charAt(0).toUpperCase() + title.slice(1)"
        :dark="true"
        :hide-on-scroll="false"
        style="font-size: 1.25rem"
        @theme="themeSwitched"
        v-if="showAppBar"
      >
        <template v-if="me">
          <p class="pt-4 mr-3" v-if="!$vuetify.breakpoint.mobile">
            <v-icon class="pb-1">mdi-account</v-icon>
            <span class="ml-2 text-body-1 font-weight-bold">
              {{ (me && me.username) || 'user' }}
            </span>
          </p>
          <v-btn
            text
            href="http://mappingtool.projects-espaciainserta.com:3000/dashboard"
          >
            <v-icon class="ml-1 mr-1" small>mdi-chart-line</v-icon>
            Analytics
          </v-btn>
          <v-btn text @click="signoutDialog = true">
            Sign out
            <v-icon class="ml-1" small>mdi-logout</v-icon>
          </v-btn>

          <v-dialog
            v-model="signoutDialog"
            :max-width="400"
            @keydown.esc="signoutDialog = false"
          >
            <v-card>
              <v-toolbar dark color="primary" dense flat>
                <v-toolbar-title class="text-body-1 font-weight-bold white--text">
                  Sign out confirmation
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-4 black--text">
                Are you sure you want to sign out?
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  class="body-2 font-weight-bold"
                  @click.native="signoutDialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  href="/auth/logout"
                  @click.native="signoutDialog = false"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </bml-app-bar>

      <bml-sidebar
        v-model="showDrawer"
        :mini="false"
        :items="menu"
        :logo="logo"
        :logos-height="80"
        logo-alt="Accessibilitech logo"
        title="Accessibilitech"
        :expand-on-hover="false"
        :miniable="false"
        v-if="showSideBar"
      >
        <template #append>
          <v-row class="mt-4" justify="center" align="center">
            <v-col cols="12" justify="center" align="center" class="mb-0 pb-0">
              <v-img
                alt="Founded by the European Union"
                contain
                max-width="120"
                src="@/assets/img/eu.png"
              />
            </v-col>
            <v-col cols="10" class="mt-1 pt-0">
              <p class="disclaimer text-center">
                This project has received funding from the European Union's Rights,
                Equality and Citizenship Programme (2014-2020).
              </p>
            </v-col>
          </v-row>
          <v-row justify="center" style="padding: 30px; padding-bottom: 10px">
            <v-tooltip right>
              <template #activator="{ on }">
                <v-btn outlined color="primary" block to="/intranet" v-on="on">
                  <v-icon>mdi-shield-account</v-icon>
                  <span class="ml-1">Intranet</span>
                </v-btn>
              </template>
              <span>Intranet</span>
            </v-tooltip>
          </v-row>
          <v-row justify="center" style="padding: 30px; padding-top: 0">
            <v-tooltip right>
              <template #activator="{ on }">
                <v-btn
                  outlined
                  color="primary"
                  block
                  target="_blank"
                  href="https://accessibilitech.accessibilitas.es"
                  aria-label="Go to Accessibilitech page"
                  v-on="on"
                >
                  <v-icon>mdi-earth</v-icon>
                  <span class="ml-1">Accessibilitech</span>
                </v-btn>
              </template>
              <span>Go to Accessibilitech page</span>
            </v-tooltip>
          </v-row>
        </template>
      </bml-sidebar>
    </div>
    <v-main>
      <!-- Main page content here -->
      <router-view />
    </v-main>
    <!-- Snackbar for global alerts from Vuex -->
    <bml-snackbar use-vuex />
  </v-app>
</template>

<script>
import Avatar from '@bigml/bmlapp/src/assets/img/user/avatar.png'
import { mapActions, mapGetters } from 'vuex'

import Logo from '@/assets/img/logo.png'
import { APP_TITLE } from '@/core/config'
import { items } from '@/core/config/sidebar-menu-items'

export default {
  name: 'App',
  components: {},
  data: function () {
    return {
      logo: Logo,
      avatar: Avatar,
      showDrawer: true,
      title: APP_TITLE,
      menu: items,
      visibilityObserver: null,
      authPolling: null,
      signoutDialog: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isUserLoggedIn']),
    ...mapGetters('user', ['me']),
    ...mapGetters({
      theme: 'theme',
      showAppBar: 'showAppBar',
      showSideBar: 'showSideBar'
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, _from) {
        this.title = to.meta.title || this.title
        if (this.$refs.appbar) {
          this.$refs.appbar.focusTitle()
        }
      }
    }
  },
  created() {
    this.visibilityObserver = () => {
      // The visibilitychange event is fired at the document
      // when the contents of its tab have become visible or have been hidden.
      const { hidden } = document
      this.togglePollingRefreshToken(!hidden)
    }
    document.addEventListener('visibilitychange', this.visibilityObserver, false)
    this.$vuetify.theme.dark = this.theme == 'dark'
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.visibilityObserver)
    this.togglePollingRefreshToken(false)
  },
  methods: {
    ...mapActions({
      togglePollingRefreshToken: 'auth/togglePollingRefreshToken',
      themeSwitched: 'setThemeDarkBool'
    })
  }
}
</script>

<style scoped lang="scss">
.disclaimer {
  font-size: 13px;
  font-style: italic;
}
</style>
